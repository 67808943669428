import React from "react";
import styled from "styled-components";
import { gridSquares } from "../../styles/grid";

import { WiderPageSection } from "../WiderPageSection";

import { AnchorSectionHeading } from "../AnchorHeading";
import { DemoPageDataContextProvider } from "./DemoPageProductContext";
import {
    SectionChooseAdventureTitle,
    SectionChooseYourOwnAdventure,
} from "./SectionChooseYourOwnAdventure";
import { SectionSandbox } from "./SectionSandbox";
import { SectionProductTourWebinar } from "./SectionProductTourWebinar";
import { SectionPartners } from "./SectionPartners";
import { PageSectionContainer } from "../PageSection";

export const DemoPageContents: React.FC = () => {
    return (
        <DemoPageDataContextProvider>
            <SectionWhiteBackground>
                <Section>
                    <SectionChooseAdventureTitle />
                </Section>

                <Section>
                    <SectionChooseYourOwnAdventure />
                </Section>
            </SectionWhiteBackground>
            <SectionDimBackground>
                <WiderSection>
                    <AnchorSectionHeading
                        id="sandbox"
                        title="Sandbox"
                        subtitle="Welcome to the Easy Agile Sandbox, you can try a full version of our apps without the need to install or login."
                    />

                    <SectionSandbox />
                </WiderSection>
            </SectionDimBackground>
            <SectionWhiteBackground>
                <WiderSection>
                    <AnchorSectionHeading
                        id="product-tour"
                        title="Take a Product Tour"
                        subtitle="We’ll walk you through how our apps for Jira can make agile easier for your team. See how our apps support key agile ceremonies, helping you achieve your product goals."
                    />

                    <SectionProductTourWebinar />
                </WiderSection>
            </SectionWhiteBackground>
            <SectionPartners />
        </DemoPageDataContextProvider>
    );
};

const Section = styled(WiderPageSection)`
    margin-bottom: ${gridSquares(1)};
`;

const WiderSection = styled(PageSectionContainer)`
    margin-bottom: ${gridSquares(1)};
`;

const SectionWhiteBackground = styled.div`
    width: 100%;
    padding-bottom: ${gridSquares(3)};
`;

const SectionDimBackground = styled.div`
    background-color: ${(p) => p.theme.color.background.dim};
    width: 100%;
    padding-bottom: ${gridSquares(3)};
`;
