import { LANDING_PAGE_DESCRIPTION } from "../constants";
import React from "react";
import { Head } from "../components/Head";

import { DemoPageContents } from "../components/Demo/DemoPageContents";
import { ContentGroups } from "../constants/contentGroups";
const DemoPage = () => {
    return (
        <>
            <Head
                contentGroup={ContentGroups["product-aware"]}
                title="Easy Agile Demo"
                description={LANDING_PAGE_DESCRIPTION}
            />
            <DemoPageContents />
        </>
    );
};

export default DemoPage;
