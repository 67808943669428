import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useEventListener } from "../../util/useEventListener";
import { Hyperlink } from "../Link/Hyperlink";
import { GetSessionUTM } from "../SessionUTMStorage";

const BASE_WIDTH = 560;
const BASE_HEIGHT = 315;

// How this wrapper lays out content
//
// + <----------bigmarker wrapper ------------> +
// |    + --  actual video content --  +        |
// |    |                              |        |   the "actual video content" should maintain aspect ratio
// |    |       TR Highlights          |        |
// |    |______________________________|        |   bigmarker wrapper
// +--------------------------------------------+      -  height should = actual video content height
//                                                     -  width should take 100% space to calculate perfect aspect ratio
//
//                                                  we deliberately try to prevent resize unless you really mean it
//                                                  because you'll lose tracking in your video
//
//                                                  during hydration we show a purple placeholder to eliminate flash

const BigMarkerWrapper = styled.div<{ height: string }>`
    width: 100%;
    height: ${(p) => p.height};

    position: relative;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        border: none;
        transition: height 0.2s, width 0.2s;
    }
`;

interface Props {
    conferenceId?: string;
    thumbnail?: string | IGatsbyImageData;
    link?: string;
}

interface SizeSettings {
    width: number;
    height: number;
}
const PlaceholderFrame = styled.div<{ width: string; height: string }>`
    background-color: ${(p) => p.theme.color.primary.main};
    width: ${(p) => p.width};
    height: ${(p) => p.height};
    position: absolute;
    top: 0;
    left: 0;
    border: none;
    transition: height 0.2s, width 0.2s;
`;

const SpoofMediaPlayerImg = styled.img`
    height: 100%;
    aspect-ratio: 16 / 9;
    margin: auto;
`;

const SpoofMediaPlayerGatsby = styled(GatsbyImage)`
    height: 100%;
    aspect-ratio: 16 / 9;
    margin: auto;
`;

const FALLBACK_UTM =
    "&utm_source=easyagile&utm_medium=web&utm_content=ea-demo-page";
/**
 * Notably the conferenceId is the recordingId from the embedded player
 * This component can be used in Sanity as well as in hard coded pages.
 *
 *  <BigMarkerPlayer conferenceId={WebinarRecordings.TeamRhythmProductTour}/>
 * @returns
 */
export const BigMarkerPlayer = ({ conferenceId, thumbnail, link }: Props) => {
    const [playerSize, setPlayerSize] = useState<SizeSettings>({
        width: BASE_WIDTH,
        height: BASE_HEIGHT,
    });
    const [utmAttribution, setUtmAttribution] = useState<string>(
        GetSessionUTM()
    );
    const isBrowser = typeof window !== undefined;
    const bigMarkerWrapperRef = useRef<HTMLDivElement>();
    const [isFrameLoading, setIsFrameLoading] = useState(true);

    // Resize handler. Changing these values resets the video (annoying), so this is designed
    // to avoid it for small adjustments of window width.
    const getSize = () => {
        // Don't ever do this without a window
        const bigMarkerEl = bigMarkerWrapperRef.current;
        if (!bigMarkerEl) {
            return;
        }

        // Get the width of the wrapper
        const currentWidth = bigMarkerEl.clientWidth;

        // Change in increments of 1/4 the base width, not going below half the base width.
        const widthIncrement = BASE_WIDTH / 4;
        const targetWidth =
            Math.floor(currentWidth / widthIncrement) * widthIncrement;
        // Set the target height to keep the original aspect ratio
        const targetHeight = (targetWidth * BASE_HEIGHT) / BASE_WIDTH;

        setPlayerSize({
            width: targetWidth,
            height: targetHeight,
        });
    };
    // send the UTM straight through to bigmarker so we can track
    // if an ad campaign drove a registration
    const updateUTMAttribution = () => {
        if (window && window.sessionStorage) {
            const sessionUTM = GetSessionUTM();
            if (sessionUTM && utmAttribution !== sessionUTM) {
                setUtmAttribution(sessionUTM);
            }
        }
    };
    useEffect(() => {
        if (typeof window !== undefined) {
            getSize();
        }
    }, []);

    useEventListener("resize", getSize);
    useEventListener("restoreSessionUTM", updateUTMAttribution);

    const currentUTM = `${
        utmAttribution || FALLBACK_UTM
    }&utm_bmcr_source=ea-demo-page`;

    if (thumbnail && link) {
        const urlWithUTM = new URL(link);
        urlWithUTM.search = currentUTM;

        return (
            <BigMarkerWrapper
                height={`${playerSize.height}px`}
                ref={bigMarkerWrapperRef}
            >
                <Hyperlink href={`${urlWithUTM}`}>
                    {typeof thumbnail === "string" ? (
                        <SpoofMediaPlayerImg src={thumbnail} />
                    ) : (
                        <SpoofMediaPlayerGatsby image={thumbnail} alt="" />
                    )}
                </Hyperlink>
            </BigMarkerWrapper>
        );
    }

    return (
        <BigMarkerWrapper
            height={`${playerSize.height}px`}
            ref={bigMarkerWrapperRef}
        >
            {isBrowser && (
                <iframe
                    allow="encrypted-media"
                    allowFullScreen={true}
                    frameBorder={0}
                    onLoadStart={() => {
                        setIsFrameLoading(true);
                    }}
                    onLoad={() => {
                        setTimeout(() => {
                            setIsFrameLoading(false);
                        }, 20);
                    }}
                    src={`https://www.bigmarker.com/recordings/${conferenceId}?width=${playerSize.width}&height=${playerSize.height}${currentUTM}`}
                    height={`${playerSize.height}px`}
                    width={`${playerSize.width}px`}
                ></iframe>
            )}
            {(isFrameLoading || !isBrowser) && (
                <PlaceholderFrame
                    width={`${playerSize.width}px`}
                    height={`${playerSize.height}px`}
                />
            )}
        </BigMarkerWrapper>
    );
};
