import React from "react";
import styled from "styled-components";
import { gridSquares } from "../../styles/grid";

const WiderPageSectionOuterLayout = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
`;

/**
 * @deprecated Use `PageSectionContainer`
 */
export const WiderPageSectionInnerLayout = styled.div`
    width: 100%;
    max-width: ${gridSquares(60)};
    padding: 0 ${(p) => p.theme.padding.pageSide};

    display: flex;
    flex-direction: column;
    align-items: center;
`;

/**
 * @deprecated Use `PageSectionContainer`
 */
export const WiderPageSection = ({ children }) => (
    <WiderPageSectionOuterLayout>
        <WiderPageSectionInnerLayout>{children}</WiderPageSectionInnerLayout>
    </WiderPageSectionOuterLayout>
);
