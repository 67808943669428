import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback,
} from "react";
import { useStaticQuery, graphql } from "gatsby";
import queryString from "query-string";

export const DEFAULT_DEMO_PRODUCT_SLUG = "teamrhythm";
const DemoPageProductContext = createContext<any | undefined>(undefined);

/**
 * The goodies that you can get from useDemoProductContext
 */
interface DemoPageProductContextProps {
    /** all of the product icons, titles, page slugs */
    allSanityProducts: SanityProductListingProps[];
    /** the currently selected product in product page slug format */
    selectedProductSlug: string;
    /** set the currently selected product */
    setSelectedProductSlug(slug: string): void;
}

/**
 * this will return a getter/setter for setting the selected product slug and all the products in sanity
 * @returns { }
 */
export const useDemoPageProductContext = ():
    | DemoPageProductContextProps
    | undefined => {
    const demoPageProductContext = useContext(DemoPageProductContext);
    return demoPageProductContext;
};

/**
 * pick off the ?product=someproduct and return the slug
 * we pass in allSanityProducts so we're guaranteed a slug that is actually a product.
 * if someone does ?product=Elephants it will return teamrhythm
 * if someone does ?product=RoadMaps it will lowercase it, find it and return roadmaps.
 * @param allSanityProducts
 * @returns
 */
const getProductSlugFromQueryParams = (
    allSanityProducts: SanityProductListingProps[]
): string => {
    if (typeof window !== "undefined") {
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.product) {
            const productSlug = (queryParams.product as string).toLowerCase();
            // only return if the slug is valid
            if (
                productSlug &&
                FindSanityProduct({
                    allSanityProducts,
                    slug: productSlug,
                })
            ) {
                return productSlug;
            }
        }
    }
    return DEFAULT_DEMO_PRODUCT_SLUG; // return teamrhythm
};

/**
 * This looks for a Product inside the query from the sanity CMS
 * We are matching here on the productPage slug to find the product.
 * @param param0
 * @returns
 */
export const FindSanityProduct = ({
    allSanityProducts,
    slug,
}): SanityProductListingProps => {
    if (!slug) return null;
    if (!allSanityProducts) return null;

    const matchingSanityProduct = allSanityProducts.find(
        (product) => slug === product?.productPage?.slug?.current
    );

    return matchingSanityProduct as SanityProductListingProps;
};

/**
 * This is a data context which handles the currently selected product
 * for the whole of the demo page. It also provides product info from Sanity.
 *
 * When you change the selected product, the query string for the page changes
 * SO THAT copying the URL in the nav bar will flip you to the right product.
 * @param param0
 * @returns
 */
export const DemoPageDataContextProvider = ({ children }) => {
    const [selectedProductSlug, _setSelectedProductSlug] = useState(
        DEFAULT_DEMO_PRODUCT_SLUG
    );

    const data = useStaticQuery(
        graphql`
            query {
                allSanityProduct {
                    products: nodes {
                        title
                        atlassianProductId
                        icon {
                            asset {
                                gatsbyImageData(placeholder: NONE)
                            }
                        }
                        productPage {
                            slug {
                                current
                            }
                        }
                    }
                }
            }
        `
    );

    /**
     * this is the magic that updates the ?product= whenever the selectedProductSlug changes
     */
    const setSelectedProductSlug = useCallback(
        (productSlug) => {
            _setSelectedProductSlug(productSlug); // < setState
            if (typeof window !== undefined) {
                // update the selected product whenever the url is chosen
                const url = new URL(window.location.href);
                url.searchParams.set("product", productSlug);

                // it is with utmost sorrow that we are using this API instead of
                // the navigate function.  if we call navigate here it will change
                // the scroll position of the page, which will affect the user experience
                // as they're clicking through the product selector

                // navigate is a function that comes to us via gatsby an is typically preferred
                // so as to use a page that's been pre-fetched.  the navigate function is based off
                // of the reach router API.

                // You can learn more about the navigate function here and why you
                // should normally use it in lieu of this API
                // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/#how-to-use-the-navigate-helper-function
                // https://reach.tech/router/api/navigate

                window.history.replaceState(null, null, url);
            }
        },
        [_setSelectedProductSlug]
    );

    const allSanityProducts = data.allSanityProduct.products;

    useEffect(() => {
        setSelectedProductSlug(
            getProductSlugFromQueryParams(allSanityProducts)
        );
    }, [allSanityProducts]);

    return (
        <>
            <DemoPageProductContext.Provider
                value={
                    {
                        allSanityProducts,
                        selectedProductSlug,
                        setSelectedProductSlug,
                    } as DemoPageProductContextProps
                }
            >
                {children}
            </DemoPageProductContext.Provider>
        </>
    );
};

/**
 * The icon, title and slug out of sanity
 * note for the icon, use getSrc() to turn it into something
 * you can use for the <img> tag.
 */
export interface SanityProductListingProps {
    title: string;
    icon: {
        asset: {
            gatsbyImageData: any;
        };
    };
    productPage: {
        slug: {
            current: string;
        };
    };
}
