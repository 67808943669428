import React from "react";
import styled from "styled-components";
import { gridSquares } from "../styles/grid";
import { Heading, HeadingLevel } from "./Typography/Heading";

import { Flex } from "./ThemeFlex";

interface AnchorInterface {
    title: string;
    subtitle?: string;
    id?: string;
    level?: HeadingLevel;
    levelStyle?: HeadingLevel;
}

export const AnchorHeading = ({
    title,
    id,
    level = "h3",
    levelStyle,
}: AnchorInterface) => {
    const anchor = id || title.replace(/\s\?/g, "-").toLowerCase();

    return (
        <Flex flexDirection="row">
            <a href={`#${id}`}>
                <Title
                    id={anchor}
                    level={level}
                    levelStyle={levelStyle}
                    onClick={() => {
                        window.location.hash = `${anchor}`;
                    }}
                >
                    {title}
                </Title>
            </a>
        </Flex>
    );
};

export const AnchorSectionHeading = ({
    title,
    subtitle,
    id,
    level = "h3",
}: AnchorInterface) => {
    return (
        <HeadingContainer>
            <Centered>
                {title && (
                    <AnchorHeading
                        title={title}
                        id={id}
                        level={level}
                        levelStyle={level}
                    />
                )}
                {subtitle && <SubTitle>{subtitle}</SubTitle>}
            </Centered>
        </HeadingContainer>
    );
};

const SubTitle = styled.span`
    max-width: 600px;
    text-align: center;
    margin-top: 0.5em;
`;
const HeadingContainer = styled.div`
    padding-top: ${gridSquares(5)};
    padding-bottom: ${gridSquares(3.5)};
    width: 100%;
    display: flex;

    flex-direction: column;
    justify-content: center;
`;
const Centered = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled(Heading)`
    scroll-margin-top: ${(p) => p.theme.height.navBar};
    margin: 0;
    word-break: break-word;
    cursor: pointer;
`;
