/* tslint:disable:object-literal-sort-keys */

import { EA_SOCIALS_LIST, EA_WWW_WEBSITE_URL } from "../constants/urls";

/**
 * Defines a <meta> tag
 */
export interface MetaTag {
    content: string;
    name?: string; // some meta tags use name
    property?: string; // others use property
}

/**
 * Basic building block of structural JSON - there are
 * many types, they're all listed in https://schema.org
 */
export interface StructuralJSONBlock {
    "@context": string;
    "@id"?: string;
    "@type": string;
}
/**
 * Defines the title, search description, sharing images for social media.
 * <meta name="description" content="description of page">
 *
 * @param url
 * @param description
 * @param imageUrl
 * @param type
 * @param author
 * @param title
 * @param preventIndex
 */
export const getMetaTags = (
    url: string,
    description: string,
    imageUrl: string,
    type: string,
    title: string,
    preventIndex: boolean
): MetaTag[] => {
    const EASY_AGILE_TWITTER_USER = "@easyagile";

    const metaInfo: MetaTag[] = [
        // this is REALLY subtle, but some meta tags use <meta name="">, and some use <meta property="">.

        // Open Graph / Facebook properties
        {
            content: url,
            name: "og:url",
        },
        {
            content: description,
            name: "description",
        },
        {
            content: title,
            property: "og:title",
        },
        {
            content: "en",
            property: "og:locale",
        },
        {
            content: "Easy Agile",
            property: "og:site_name",
        },
        {
            content: description,
            property: "og:description",
        },
        {
            content: imageUrl,
            property: "og:image",
        },
        {
            content: imageUrl,
            property: "og:image:secure_url",
        },
        {
            content: title,
            property: "og:image:alt",
        },
        {
            content: type,
            property: "og:type",
        },
        // Twitter properties
        {
            content: "summary_large_image",
            name: "twitter:card",
        },
        {
            content: EASY_AGILE_TWITTER_USER,
            property: "twitter:creator",
        },
        {
            content: EASY_AGILE_TWITTER_USER,
            property: "twitter:site",
        },
        {
            content: title,
            property: "twitter:image:alt",
        },
        {
            content: title,
            name: "twitter:title",
        },
        {
            content: description,
            name: "twitter:description",
        },
        {
            content: imageUrl,
            name: "twitter:image",
        },
        {
            name: "msvalidate.01", // bing webmaster validation
            content: "838F9D5CBA9C82AD2602BBC1BED77561",
        },
    ];

    if (preventIndex) {
        metaInfo.push({
            content: "noindex",
            name: "robots",
        });
    }
    return metaInfo;
};

/**
 * Organization
 * Schema: https://schema.org/Organization
 * @returns definition of the Easy Agile company
 *
 * Keep up to date: as you add other social platforms, update sameAs.
 */
interface OrganizationDefintion extends StructuralJSONBlock {
    name: string;
    url: string;
    logo: object;
    sameAs?: string[];
}
/**
 * This can be used in the publisher field for blogs, etc
 * @returns basic organization definition
 */
const getOrganizationDefinition = (): OrganizationDefintion => ({
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": `${EA_WWW_WEBSITE_URL}/#organization`,
    url: EA_WWW_WEBSITE_URL,
    name: "Easy Agile",
    // logo that appears in google knowledge panel
    logo: {
        "@id": EA_WWW_WEBSITE_URL,
        url: `${EA_WWW_WEBSITE_URL}/easy-agile-logo-112x112.png`,
        "@type": "ImageObject",
        width: 112,
        height: 112,
        caption: "EasyAgile",
    },
});
const getFullOrganizationDefinition = () => {
    return {
        ...getOrganizationDefinition(),
        sameAs: EA_SOCIALS_LIST,
    };
};
/**
 * Website
 * Schema: https://schema.org/WebSite
 * @returns the LD+JSON block representing easyagile.com
 *
 * Later: add searchAction to this.
 */
interface WebsiteDefinition extends StructuralJSONBlock {
    name: string;
    url: string;
    publisher: object;
}
const getWebsiteDefinition = (): WebsiteDefinition => ({
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": `${EA_WWW_WEBSITE_URL}/#website`,
    url: EA_WWW_WEBSITE_URL,
    name: "Easy Agile",
    publisher: getOrganizationDefinition(),
});

/**
 * Webpage
 * Schema: https://schema.org/WebPage
 * @returns the LD+JSON block representing the current page
 */
interface WebPageDefinition extends StructuralJSONBlock {
    "@context": string;
    "@type": string;
    name: string;
    url: string;
    description: string;
    publisher: OrganizationDefintion;
}

const getWebPageDefinition = (
    url: string,
    title: string,
    description: string
): WebPageDefinition => {
    return {
        "@context": "http://schema.org",
        "@type": "WebPage",
        name: title || "Easy Agile", // Should match webpage title
        url,
        description: description || "Agile made easy for everyone on your team", // Should match meta description
        publisher: getOrganizationDefinition(),
    };
};

/**
 * BreadcrumbList
 * Appears for second level pages, e.g. /blog, /blog/articlename
 * schema: https://schema.org/BreadcrumbList
 * @param url
 * @returns LD+JSON block representing nav hierarchy
 */
interface BreadcrumbListItemDefinition extends StructuralJSONBlock {
    position: number;
    item: string;
    name: string;
}
interface BreadcrumbListDefinition extends StructuralJSONBlock {
    name: string;
    itemListElement: BreadcrumbListItemDefinition[];
}

export const getBreadcrumbDefinition = (
    url: string
): BreadcrumbListDefinition => {
    try {
        // take the full url and grab the path (/blog/blognamehere)
        const path = new URL(url).pathname;

        if (path === "/") {
            // only return a breadcrumb result on a second level navigation
            return null;
        }

        const pathParts = path
            .split("/")
            .filter((pathPart) => pathPart.length > 0);

        // rebuild the path to the current item.
        let urlBase = `${EA_WWW_WEBSITE_URL}/`;

        //
        const breadCrumbItemList = [] as BreadcrumbListItemDefinition[];
        for (let i = 0; i < pathParts.length; i++) {
            urlBase += pathParts[i] + "/";
            breadCrumbItemList.push({
                "@type": "ListItem",
                "@context": "https://schema.org",
                position: i + 1, // Schema is 1-indexed (starts from 1)
                name: pathParts[i],
                item: urlBase,
            });
        }
        return {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "@id": url + "#breadcrumblist",
            name: "Easy Agile",
            itemListElement: breadCrumbItemList,
        };
    } catch (e) {
        return null;
    }
};

/**
 * parse the date, specially handling Safari which can't cope with what Sanity serializes
 * @param date string from sanity representing the date
 * @returns
 */
export const getDateFromSanityFormat = (date) => {
    if (!date) {
        return new Date();
    }
    // the system can handle the date
    if (!isNaN(Date.parse(date))) {
        return new Date(date);
    }

    // try the date without commas - chrome can parse this but not safari
    // "07, July 2023" -> "07 July 2023"
    const hasWeirdSanityCommaFormat = /^\d{2},\s\w+\s\d{4}$/;

    if (hasWeirdSanityCommaFormat.test(date)) {
        const dateNoComma = date.replace(/,/, "");
        if (!isNaN(Date.parse(dateNoComma))) {
            return new Date(dateNoComma);
        }
    }
    return new Date();
};

/**
 * BlogPosting
 * Appears for content that contains an article
 * schema: https://schema.org/BlogPosting
 * @param publishedAt
 * @param contents
 * @param metaInfo
 * @returns JSON LD block representing a blog post
 */
interface ArticleDefintion extends StructuralJSONBlock {
    image: string;
    url: string;
    headline: string;
    dateCreated: string; // "2022-04-01T00:00:00.000Z",
    datePublished: string;
    dateModified: string;
    inLanguage: string;
    isFamilyFriendly: boolean;
    copyrightYear: number;
    copyrightHolder: string;
    contentLocation: object;
    publisher: object;
    author: object;
    mainEntityOfPage: string;
    keywords: string[];
    articleBody: string;
}
export const getBlogPostDefinition = (
    publishedAt: string,
    contents: any,
    keywords: string[],
    metaInfo: MetaTag[]
): ArticleDefintion => {
    if (!metaInfo) throw new Error("Missing meta information");

    // map back to the social sharing meta tags to ensure
    // single source of truth
    const ogImage = metaInfo.find(
        (metaTag: MetaTag) =>
            metaTag.property === "og:image" || metaTag.name === "og:image"
    );
    const ogUrl = metaInfo.find(
        (metaTag: MetaTag) =>
            metaTag.property === "og:url" || metaTag.name === "og:url"
    );
    const ogTitle = metaInfo.find(
        (metaTag: MetaTag) =>
            metaTag.property === "og:title" || metaTag.name === "og:title"
    );

    const modifiedDate = getDateFromSanityFormat(publishedAt);

    let articleBody = "";
    if (contents) {
        // _rawBody of text.  kept in blocks
        // https://www.sanity.io/docs/presenting-block-text
        // map sanity to plain text.  newlines become spaces.
        articleBody = contents
            // loop through each block
            .map((block) => {
                // if it's not a text block with children,
                // return nothing
                if (block._type !== "block" || !block.children) {
                    return "";
                }
                // loop through the children spans, and join the
                // text strings
                return block.children.map((child) => child.text).join("");
            })
            // join the paragraphs with a space
            .join(" ");
    }

    return {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        image: ogImage.content,
        url: ogUrl.content,
        headline: ogTitle.content,
        dateCreated: modifiedDate.toISOString(), // "2022-04-01T00:00:00.000Z",
        datePublished: modifiedDate.toISOString(),
        dateModified: modifiedDate.toISOString(),
        inLanguage: "en-US",
        isFamilyFriendly: true,
        copyrightYear: modifiedDate.getFullYear(),
        copyrightHolder: "Easy Agile Pty Ltd",
        contentLocation: {
            "@type": "Place",
            name: "NSW, Australia",
        },
        publisher: getOrganizationDefinition(),
        author: {
            "@type": "Organization",
            "@id": EA_WWW_WEBSITE_URL + "/#organization",
        },
        mainEntityOfPage: "True",
        // for now the blog tags will serve as additional keywords, review in future
        keywords: [...keywords, "Easy Agile", "Agile planning tools", "Jira"],
        articleBody,
    };
};

/**
 * Get the default SEO for a particular webpage
 * To extend the default page seo, for one particular page,
 * use the onCustomizeStructuralJSON callback in the <Head> tag.
 *
 * @param url
 * @param title
 * @param description
 * @returns
 */
export const getDefaultPageSEO = (
    url: string,
    title: string,
    description: string
): StructuralJSONBlock[] => {
    const baseSEO = [
        getFullOrganizationDefinition(),
        getWebsiteDefinition(),
        getWebPageDefinition(url, title, description),
    ] as StructuralJSONBlock[];

    // if we on a second layer of the site? use a breadcrumb
    const breadcrumbSEO = getBreadcrumbDefinition(url);
    if (breadcrumbSEO) {
        return [...baseSEO, breadcrumbSEO];
    }

    return baseSEO;
};
