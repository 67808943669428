import React from "react";
import styled from "styled-components";
import { gridSquares } from "../../styles/grid";
import { Paragraph } from "../Typography/Paragraph";
import { Heading } from "../Typography/Heading";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import { theme } from "../../styles/theme";

// @ts-expect-error
import productTourIcon from "../../../content/imgs/icons/productTourIcon.svg";
// @ts-expect-error
import partnerIcon from "../../../content/imgs/icons/partnerIcon.svg";
// @ts-expect-error
import demoIcon from "../../../content/imgs/icons/demoIcon.svg";
// @ts-expect-error
import sandboxIcon from "../../../content/imgs/icons/sandboxIcon.svg";

export const SectionChooseAdventureTitle = (): React.ReactElement => {
    return (
        <>
            <Title level="h1">See us in action</Title>
            <TitleBlurb>
                Our apps for Jira make agile easier, and we’d love to show you
                how. Select an option below to see how Easy Agile can help you
                and your team work better together, and deliver value at scale.
            </TitleBlurb>
        </>
    );
};

const TitleBlurb = styled.p`
    text-align: center;
    width: 65%;
    padding-bottom: ${gridSquares(4)};
`;

const Title = styled(Heading)`
    padding-top: 1.5em;
    /* padding-bottom: 1em; */
    margin-bottom: 0.5em;
`;

export const SectionChooseYourOwnAdventure = (): React.ReactElement => {
    return (
        <ButtonGrid>
            <OptionButton
                title="Sandbox"
                link="sandbox"
                icon={sandboxIcon}
                content="Try our apps on a sample Jira board; no installation required"
                bgColour={(p) => p.theme.color.products.roadmaps}
                darkText={false}
                conversionId={"option_sandbox"}
            />
            <OptionButton
                title="Product Tour"
                link="product-tour"
                icon={productTourIcon}
                content="Watch and interact with a guided video of our apps"
                bgColour={(p) => p.theme.color.products.personas}
                darkText={true}
                conversionId={"option_producttour"}
            />
            <OptionButton
                title="Find a Partner"
                link="partner"
                icon={partnerIcon}
                content="Find help with licensing, installation, and getting started"
                bgColour={(p) => p.theme.color.products.storymaps}
                darkText={true}
                conversionId={"option_findpartner"}
            />
            <OptionButton
                title="Ask a Question"
                href="https://arijea.atlassian.net/servicedesk/customer/portal/2/create/119"
                icon={demoIcon}
                content="We’ll answer your questions to help find the right solution"
                bgColour={(p) => p.theme.color.products.programs}
                darkText={true}
                conversionId={"option_bookdemo"}
            />
        </ButtonGrid>
    );
};

const ButtonGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 300px);
    gap: ${gridSquares(1)};
    align-items: stretch;
    justify-items: stretch;

    ${(p) => p.theme.media("sm")} {
        grid-template-columns: repeat(2, 300px);
    }
    ${(p) => p.theme.media("md")} {
        grid-template-columns: repeat(2, 300px);
    }
    ${(p) => p.theme.media("lg")} {
        grid-template-columns: repeat(2, 300px);
    }
    ${(p) => p.theme.media("xl")} {
        grid-template-columns: repeat(4, 300px);
    }
`;

interface OptionButtonInterface {
    title;
    icon;
    content;
    link?;
    bgColour;
    darkText;
    conversionId;
    href?;
}

const OptionButton = (props: OptionButtonInterface) => {
    return (
        <StyledBlock
            bgColour={props.bgColour}
            darkText={props.darkText}
            data-conversionid={props.conversionId}
            onClick={(e) => {
                if (props.href) {
                    window.open(props.href, "_blank");
                }
                const anchorEl = document.getElementById(props.link);
                if (anchorEl) {
                    const clientRect = anchorEl.getBoundingClientRect();

                    // make sure to accomodate the scrollMargin
                    window.scrollTo({
                        left: 0,
                        top: clientRect.top - parseInt(theme.height.navBar, 10),
                        behavior: "smooth",
                    });
                }
            }}
        >
            <ContentContainer>
                <Icon src={props.icon} />
                <BoldHeading level="h5">{props.title}</BoldHeading>
                <Blurb>
                    {props.content}
                    <ChevronRight />
                </Blurb>
            </ContentContainer>
        </StyledBlock>
    );
};

const Blurb = styled(Paragraph)`
    margin: 0px;
`;

const Icon = styled.img`
    height: 60px;
    display: inline-block;
`;

const ContentContainer = styled.div`
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
    max-width: 80%;
`;

const BoldHeading = styled(Heading)`
    font-weight: 700;
    margin: 0px;
    padding-top: 1em;
    padding-bottom: 0.5em;
`;

const ChevronRight = styled(MdChevronRight)`
    && {
        display: inline;
        font-size: inherit;
        margin: 0;
        vertical-align: middle;
    }
`;
interface StyledProps {
    readonly bgColour: string;
    readonly darkText: boolean;
}

const StyledBlock = styled.div<StyledProps>`
    width: 300px;
    border-radius: ${(p) => p.theme.borderRadius.m};
    background-color: ${(props) => props.bgColour};
    color: ${(props) =>
        props.darkText
            ? (p) => p.theme.color.primary.main
            : (p) => p.theme.color.secondaryOne.contrastText};
    padding: ${gridSquares(1)};
    cursor: pointer;

    svg {
        margin-bottom: 4px;
        transition: translate 0.2s;
    }
    &:hover {
        svg {
            translate: 0.2em;
        }
    }
`;
