// keep this in sync with contentGroups in studio folder
export enum ContentGroups {
    "default" = "default",
    "limited-aware" = "limited-aware",
    "agile-aware" = "agile-aware",
    "product-aware" = "product-aware",
    "marketing-qualified-lead" = "marketing-qualified-lead",
    "sales-qualified-lead" = "sales-qualified-lead",
    "product-qualified-lead" = "product-qualified-lead",
    "careers" = "careers",
    "company" = "company",
    "partners" = "partners",
    "support" = "support",
    "trust-center" = "trust-center",
    "existing-customer" = "existing-customer",
}
