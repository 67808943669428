import React from "react";
import styled, { css } from "styled-components";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import { ViewportSize } from "../../styles/theme";
import { Hyperlink } from "../Link/Hyperlink";

const generateLinkButtonStyles = (viewportSize?: ViewportSize) => {
    const size = viewportSize || "xs"; // xs is default, no media query necessary

    return css`
        ${(p) => {
            const vals = p.theme.viewport[size].typography.linkButton;

            const styleRules = `
                font-size: ${vals.fontSize};
                font-weight: ${vals.fontWeight};
                letter-spacing: ${vals.letterSpacing};
                margin-top: ${vals.marginTop};
                margin-bottom: ${vals.marginBottom};

                :first-child {
                    margin-top: 0;
                }

                :last-child {
                    margin-bottom: 0;
                }
            `;

            if (size === "xs") {
                return styleRules;
            }

            return `
                ${p.theme.media(size)} {
                    ${styleRules}
                }
            `;
        }};
    `;
};

export const linkButtonStyles = css`
    ${() => generateLinkButtonStyles()};
    ${() => generateLinkButtonStyles("sm")};
    ${() => generateLinkButtonStyles("md")};
    ${() => generateLinkButtonStyles("lg")};
    ${() => generateLinkButtonStyles("xl")};
    ${() => generateLinkButtonStyles("xxl")};
    ${() => generateLinkButtonStyles("xxxl")};
`;

const LinkButtonParagraph = styled.p<{ color: string }>`
    ${linkButtonStyles}

    display: flex;
    align-items: center;
    white-space: nowrap;
    color: ${(p) => p.color || p.theme.color.secondaryOne.main};
    text-transform: uppercase;

    svg {
        transition: transform 150ms;
        margin-bottom: 2px;
        vertical-align: middle;
    }

    :hover {
        svg {
            transform: translate(0.25em, 0);
        }
    }
`;

interface LinkButtonProps {
    href: string;
    color?: string;
    className?: string;
    children: React.ReactNode;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
    href,
    children,
    color,
    className,
}) => {
    return (
        <Hyperlink href={href} className={className}>
            <LinkButtonParagraph color={color} className="link-button">
                {children}
                <MdChevronRight />
            </LinkButtonParagraph>
        </Hyperlink>
    );
};
