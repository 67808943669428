import React from "react";
import { Flex } from "../ThemeFlex";
import { gridSquares } from "../../styles/grid";
import styled, { css } from "styled-components";

import { Heading } from "../Typography/Heading";
import { GridGutterRows } from "../GridGutters";
import { LinkButton } from "../Typography/LinkButton";

import { getSrc } from "gatsby-plugin-image";

import {
    useDemoPageProductContext,
    SanityProductListingProps,
} from "./DemoPageProductContext";
import { GetDemoProductSelectionList } from "./ProductSelectorData";

/**
 * This is the product selector on the side of the webinars and the sandbox
 * when you select a product it sets it in the DemoPageProductContext which
 * then flips the URL
 * @returns
 */
export const ProductSelection: React.FC = () => {
    const { allSanityProducts } = useDemoPageProductContext();
    const productSelectionList = GetDemoProductSelectionList(allSanityProducts);
    return (
        <ProductSelectionWrapper>
            {productSelectionList.map((featuredProduct) => {
                return (
                    <Product
                        key={`product-${featuredProduct.slug}`}
                        product={featuredProduct.product}
                        blurb={featuredProduct.blurb}
                    />
                );
            })}
        </ProductSelectionWrapper>
    );
};

export interface ProductSelectionListItem {
    product: SanityProductListingProps;
    blurb: string;
}
const Product: React.FC<ProductSelectionListItem> = ({ product, blurb }) => {
    const { selectedProductSlug, setSelectedProductSlug } =
        useDemoPageProductContext();
    if (!product) return null;

    const productSlug = product?.productPage?.slug?.current;
    const iconURL = getSrc(product.icon.asset.gatsbyImageData);
    const isActive = selectedProductSlug === productSlug;

    const handleClick = () => {
        setSelectedProductSlug(productSlug);
    };

    return (
        <ProductWrapper onClick={handleClick} active={isActive}>
            <IconWrapper>
                <ProductSelectionIcon src={iconURL} active={isActive} />
            </IconWrapper>
            <Flex flexDirection="column" alignItems="flex-start">
                <ProductSelectionHeading level="h5" active={isActive}>
                    {product.title}
                </ProductSelectionHeading>
                <ProductBlurb active={isActive}>
                    <p>{blurb}</p>
                    <GridGutterRows gutters={1} />
                    <div>
                        <LinkButton href={`/products/${productSlug}`}>
                            Features & Pricing
                        </LinkButton>
                    </div>
                </ProductBlurb>
            </Flex>
        </ProductWrapper>
    );
};

const ProductBlurb = styled.div<{ active: boolean }>`
    display: none;

    ${(p) => p.theme.media("xl")} {
        display: block;

        display: ${(p) => (p.active ? "block" : "none")};
        overflow: hidden;
    }
`;

const ProductSelectionHeading = styled(Heading)<{ active: boolean }>`
    font-weight: 700;
    margin: 0px;
    padding-top: 12px;
    padding-bottom: 0.3em;
    font-weight: bold;

    :hover {
        opacity: 100%;
    }

    ${(p) => p.theme.media("xl")} {
        flex-direction: row;
        font-weight: 400;
        opacity: 60%;
        width: 146px;

        ${({ active }) =>
            active &&
            css`
                font-weight: 700;
                padding-top: 16px;
                opacity: 100%;
            `}
    }
`;

const ProductSelectionWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(120px, 2fr));
    gap: 32px;

    ${(p) => p.theme.media("sm")} {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: ${gridSquares(1)};
        justify-content: space-around;
    }

    ${(p) => p.theme.media("xl")} {
        display: flex;
        margin: auto;
        flex-direction: column;
        width: 220px;
    }
`;
const ProductWrapper = styled.div<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    gap: 10px;

    ${(p) => p.theme.media("xl")} {
        flex-direction: row;
        align-items: flex-start;
    }

    ${({ active }) =>
        !active &&
        css`
            cursor: pointer;
        `}

    :hover {
        ${ProductSelectionHeading} {
            opacity: 100%;
        }
    }
`;
const IconWrapper = styled.div`
    min-width: 60px;
    width: 60px;
    height: 60px;
`;

const ProductSelectionIcon = styled.img<{ active: boolean }>`
    height: 60px;
    width: 60px;
    display: inline-block;
    ${(p) => p.theme.media("sm")} {
        padding-bottom: 0;
    }
    ${(p) => p.theme.media("xl")} {
        ${({ active }) =>
            !active &&
            css`
                height: 46px;
                width: 46px;
            `}
    }

    transition: height 350ms, width 350ms;
`;
