import React from "react";
import { Flex } from "../ThemeFlex";
import { MdChevronRight } from "@react-icons/all-files/md/MdChevronRight";
import { gridSquares } from "../../styles/grid";
import styled from "styled-components";
import { getSrc } from "gatsby-plugin-image";

import { Heading } from "../Typography/Heading";
import { List } from "../List";
import Button from "../Button";
import { ProductSelection } from "./ProductSelector";
import { Hyperlink } from "../Link/Hyperlink";

import { GetSandboxPreviewProductData } from "./SectionSandboxData";
import { useDemoPageProductContext } from "./DemoPageProductContext";

export const SectionSandbox: React.FC = () => {
    return (
        <Flex>
            <SectionWidthContainer>
                <ProductSelection />
                <SandboxWrapper>
                    <SandBox />
                </SandboxWrapper>
            </SectionWidthContainer>
        </Flex>
    );
};

const SandBox: React.FC = () => {
    const { selectedProductSlug, allSanityProducts } =
        useDemoPageProductContext();
    if (!allSanityProducts) return null;

    const activeProduct = GetSandboxPreviewProductData({
        selectedProductSlug,
        allSanityProducts,
    });

    const iconURL = getSrc(activeProduct.icon.asset.gatsbyImageData);

    return (
        <Hyperlink href={activeProduct.url}>
            <SandboxContainer>
                <SandboxBackgroundImage src={activeProduct.backgroundImage} />

                <DemoBlurbContainer>
                    <Icon src={iconURL} />
                    <DemoContainerHeading level="h4">
                        {activeProduct.title}
                    </DemoContainerHeading>
                    <MobileLink href={activeProduct.url}>
                        {activeProduct.title} Sandbox
                        <ChevronRight />
                    </MobileLink>
                    <KeyPoints isBullets="true">
                        {activeProduct.keypoints.map((point, index) => (
                            <li key={`point${index}`}>{point}</li>
                        ))}
                    </KeyPoints>
                    <DemoCTAButton
                        href={activeProduct.url}
                        selectedProductSlug={activeProduct.slug}
                    >
                        {activeProduct.title} Sandbox
                    </DemoCTAButton>
                </DemoBlurbContainer>
            </SandboxContainer>
        </Hyperlink>
    );
};

// CONSTS

const WIDTH = 800;
const HEIGHT = 450;

const SandboxBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
`;

const DemoCTAButton = styled(Button)<{ selectedProductSlug: string }>`
    margin: auto;
    margin-top: ${gridSquares(2)};
    background-color: ${(p) => p.theme.color.products[p.selectedProductSlug]};
    border-color: ${(p) => p.theme.color.products[p.selectedProductSlug]};
    color: ${(p) => p.theme.color.productsText[p.selectedProductSlug]};

    display: none;
    &&:hover {
        color: white;
        background-color: #330066;
        border-color: white;
    }

    ${(p) => p.theme.media("xl")} {
        display: block;
    }
`;

const SandboxWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${gridSquares(1)};
    justify-content: space-around;
`;

const SectionWidthContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    width: 90%;
    gap: ${gridSquares(3)};
    flex-direction: column-reverse;

    ${(p) => p.theme.media("xl")} {
        flex-direction: row;
    }
`;

const Icon = styled.img`
    height: 60px;
    display: inline-block;
`;

const SandboxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    aspect-ratio: ${WIDTH} / ${HEIGHT};
    width: 100%;
    max-width: ${WIDTH}px;
    min-height: 200px;
    max-height: ${HEIGHT}px;

    border-radius: ${(p) => p.theme.borderRadius.l};
    overflow: hidden;

    ${(p) => p.theme.media("xl")} {
        width: ${WIDTH}px;
        height: ${HEIGHT}px;
    }
`;

const KeyPoints = styled(List)`
    justify-content: center;
    margin: auto;
    margin-top: 0;

    list-style-position: outside;
    text-align: left;
    display: none;
    ${(p) => p.theme.media("xl")} {
        display: grid;
    } ;
`;

const DemoBlurbContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    height: 50%;
    margin: auto;
    flex-direction: column;
    opacity: 1;
    text-align: center;
    color: white;
    z-index: 1;
`;

const DemoContainerHeading = styled(Heading)`
    margin: 0;
    margin-bottom: ${gridSquares(2)};
    padding-top: ${gridSquares(1)};
    display: none;

    ${(p) => p.theme.media("xl")} {
        display: block;
    }
`;

const MobileLink = styled.a`
    display: block;
    font-size: 25px;
    font-weight: bold;
    padding-top: 20px;
    color: white;
    ${(p) => p.theme.media("xl")} {
        display: none;
    }
`;

const ChevronRight = styled(MdChevronRight)`
    color: white;
    vertical-align: middle;
    ${(p) => p.theme.media("xl")} {
        display: none;
    }
`;
