// @ts-expect-error
import teamRhythmDemoBG from "../../../content/imgs/products/productUI/TeamRhythmUI.png";
// @ts-expect-error
import roadmapsDemoBG from "../../../content/imgs/products/productUI/RoadmapsUI.png";
// @ts-expect-error
import programsDemoBG from "../../../content/imgs/products/productUI/ProgramsUI.png";
// @ts-expect-error
import personasDemoBG from "../../../content/imgs/products/productUI/PersonasUI.png";

import {
    FindSanityProduct,
    SanityProductListingProps,
    DEFAULT_DEMO_PRODUCT_SLUG,
} from "./DemoPageProductContext";

export const GetSandboxPreviewProductData = ({
    selectedProductSlug,
    allSanityProducts,
}) => {
    const matchingSanityProduct = FindSanityProduct({
        allSanityProducts,
        slug: selectedProductSlug || DEFAULT_DEMO_PRODUCT_SLUG,
    });

    if (!matchingSanityProduct) return null;

    const productData = {
        teamrhythm: {
            backgroundImage: teamRhythmDemoBG,
            url: "https://demo.easyagile.com/demo/teamrhythm?demoMode=teamrhythm-onboarding",
            keypoints: [
                "Organise your Jira backlog in context on the storymap",
                "Plan sprints or versions with a picture of team capacity",
                "Learn how you work best together with retrospectives",
            ],
        },
        roadmaps: {
            backgroundImage: roadmapsDemoBG,
            url: "https://demo.easyagile.com/demo/roadmaps?demoMode=roadmaps-onboarding",
            keypoints: [
                "Simple drag and drop to schedule Jira issues on the timeline",
                "Group issues into theme swimlanes based on teams or customer outcomes",
                "Visualise key milestones to align development and business teams",
            ],
        },
        programs: {
            backgroundImage: programsDemoBG,
            url: "https://demo.easyagile.com/demo/programs?demoMode=programs-onboarding",
            keypoints: [
                "Plan and align the work of multiple teams in Jira in one tool",
                "Easily visualise features, dependencies and milestones ",
                "Align teams with a digitised Program Board and PI Objectives",
            ],
        },
        personas: {
            backgroundImage: personasDemoBG,
            url: "https://demo.easyagile.com/demo/personas?demoMode=personas-onboarding",
            keypoints: [
                "Easily integrate user personas into your work in Jira",
                "Order work by issues that offer the most value to users",
                "Help the whole team focus on who they’re building for",
            ],
        },
    };
    return {
        ...productData[selectedProductSlug],
        title: matchingSanityProduct.title,
        slug: selectedProductSlug,
        ...matchingSanityProduct,
    } as SandboxSanityProductInfo;
};

export interface SandboxSanityProductInfo extends SanityProductListingProps {
    backgroundImage: string;
    slug: string;
    url: string;
    keypoints: string[];
}
