import {
    FindSanityProduct,
    SanityProductListingProps,
} from "./DemoPageProductContext";

export const GetDemoProductSelectionList = (
    allSanityProducts: SanityProductListingProps[]
): ProductSelectionInfo[] => {
    const productSelectionList = [
        {
            slug: "teamrhythm",
            blurb: "Plan and deliver products that your customers value.",
        },
        {
            slug: "programs",
            blurb: "Scale your cross-team planning and execution.",
        },
        { slug: "roadmaps", blurb: "Simple and flexible product roadmapping." },
        {
            slug: "personas",
            blurb: "Deliver better products for your customers.",
        },
    ];

    return productSelectionList.map((productInfo) => {
        const slug = productInfo.slug;
        const sanityProduct = FindSanityProduct({ allSanityProducts, slug });
        return {
            slug: productInfo.slug,
            blurb: productInfo.blurb,
            product: sanityProduct,
        } as ProductSelectionInfo;
    });
};

export interface ProductSelectionInfo {
    product: SanityProductListingProps;
    blurb: string;
    slug: string;
}
