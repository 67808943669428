import React from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { List } from "../List";
import Button from "../Button";
import { Paragraph } from "../Typography/Paragraph";
import { PageSectionContainer } from "../PageSection";
import { graphql, useStaticQuery } from "gatsby";
import { AnchorHeading } from "../AnchorHeading";
import { GridGutterRows } from "../GridGutters";

const FillerImage = styled(GatsbyImage)``;

const Container = styled(PageSectionContainer)`
    padding-top: 80px;
    padding-bottom: 80px;
`;

const MainContent = styled.div`
    width: 100%;
`;

const SectionLayout = styled.div`
    background-color: ${(p) => p.theme.color.secondaryOne.main};
    color: ${(p) => p.theme.color.secondaryOne.contrastText};
    position: relative;
    width: 100%;

    ${(p) => p.theme.media("xl")} {
        ${MainContent} {
            width: 50%;
            padding-right: 80px;
        }

        ${FillerImage} {
            position: absolute;
            top: 0;
            right: 0;
            width: 50%;
            height: 100%;

            img {
                object-position: left center;
            }
        }
    }
`;

const ListHeading = styled(Paragraph)`
    font-weight: ${(p) => p.theme.fontWeight.heavy};
`;

export const SectionPartners = () => {
    const data = useStaticQuery(graphql`
        query FillerImage {
            image: file(name: { eq: "partner-atlas-filler" }) {
                childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                }
            }
        }
    `);

    const fillerImage = getImage(data.image);

    return (
        <SectionLayout>
            <FillerImage image={fillerImage} alt="" aria-hidden />
            <Container>
                <MainContent>
                    <AnchorHeading
                        title="Find an Easy Agile Partner"
                        level="h2"
                        levelStyle="h3"
                        id="partner"
                    ></AnchorHeading>

                    <Paragraph>
                        Partners can help you with installing, licensing, and
                        using Easy Agile apps in your language and timezone.
                    </Paragraph>
                    <ListHeading>
                        Our verified partners can help you:
                    </ListHeading>
                    <List isDashes={true}>
                        <li>Install and configure Easy Agile products</li>
                        <li>Demo Easy Agile products</li>
                        <li>Grow your Agile capabilities</li>
                        <li>
                            Consult on SAFe® practices as a Scaled Agile Partner
                        </li>
                    </List>
                    <GridGutterRows gutters={1} />
                    <Button variation="primary" href="/partners">
                        Find a Partner
                    </Button>
                    <GridGutterRows gutters={1} />
                </MainContent>
            </Container>
        </SectionLayout>
    );
};
