import { gridSquares } from "../styles/grid";
import styled from "styled-components";

interface PropsType {
    gutters?: number;
}

export const GridGutterColumns = styled.div<PropsType>`
    width: ${(p) => gridSquares(p.gutters || 1)};
    height: 100%;
`;

export const GridGutterRows = styled.div<PropsType>`
    width: 100%;
    height: ${(p) => gridSquares(p.gutters || 1)};
`;
