import React from "react";
import styled from "styled-components";
import { gridSquares } from "../../styles/grid";


import { BigMarkerPlayer } from "../Video/BigMarkerPlayer";
import { WebinarRecordings } from "./SectionProductTourWebinarData";
import { useDemoPageProductContext } from "./DemoPageProductContext";
import { ProductSelection } from "./ProductSelector";

export const SectionProductTourWebinar: React.FC = () => {
    const { allSanityProducts } = useDemoPageProductContext();
    if (!allSanityProducts) return null;

    return (
        <>
            <Container>
                <ProductSelectionWrapper>
                    <ProductSelection />
                </ProductSelectionWrapper>
                <RoundedEdges>
                    <MediaPlayer />
                </RoundedEdges>
            </Container>
        </>
    );
};

const MediaPlayer: React.FC = () => {
    const { selectedProductSlug } = useDemoPageProductContext();

    return (
        <BigMarkerPlayer
            conferenceId={WebinarRecordings[selectedProductSlug]}
        />
    );
};

const programsWebinar =
    "https://www.bigmarker.com/easy-agile/Easy-Agile-Programs-Interactive-Demo";

const ProductSelectionWrapper = styled.div`
    ${(p) => p.theme.media("xl")} {
        width: 350px;
    }
`;

const RoundedEdges = styled.div`
    display: contents;

    * > iframe {
        border-radius: 25px;
        left: 50%;
        transform: translateX(-50%);
    }

    * > a > img {
        border-radius: 25px;
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin: auto;
    width: 90%;
    gap: ${gridSquares(3)};
    flex-direction: column-reverse;

    ${(p) => p.theme.media("xl")} {
        flex-direction: row;
    }
`;
