import { Location } from "@reach/router";

import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import { EA_WEBSITE_URL, EA_WWW_WEBSITE_URL } from "../constants/urls";

import {
    getDefaultPageSEO,
    getMetaTags,
    MetaTag,
    StructuralJSONBlock,
} from "./HeadSEO";
const isBrowser = typeof window !== "undefined";

// The Cookie-Script <script> has a bug that causes an error to be thrown very
// early on and stop execution. The first thing it does is run a function which
// assumes there's a `CookieScript` global/window var and checks if it has an
// `instance` property. If not, it creates a new `CookieScript` object, then
// the `instance` prop. `CookieScript` is never created before this check is done,
// so it throws an error because it's trying to access a property of undefined.
// Creating this empty object lets the script dig itself out of the little catch 22
// situation its created for itself. The script will replace the object with
// the real thing straight away.
// @ts-ignore
if (isBrowser && !window.CookieScript) {
    // @ts-ignore
    window.CookieScript = {};
}

// for a complete schema of LD+JSON, visit https://schema.org
export type onCustomizeStructuralJSONCallback = (
    baseLdJSON: any,
    metaInfo: MetaTag[]
) => StructuralJSONBlock[];
interface Props {
    author?: string;
    image?: string;
    title?: string;
    description?: string;
    lang?: string;
    url?: string;
    type?: string;
    canonicalUrl?: string;
    preventIndex?: boolean;
    contentGroup?: string;
    onCustomizeStructuralJSON?: onCustomizeStructuralJSONCallback;
    allowCookieBanner?: boolean;
}

const getLdJSON = ({
    url,
    title,
    description,
    onCustomizeStructuralJSON,
    metaInfo,
}) => {
    const defaultPageSEO = getDefaultPageSEO(url, title, description);
    const allPageSEO = onCustomizeStructuralJSON
        ? onCustomizeStructuralJSON(defaultPageSEO, metaInfo)
        : defaultPageSEO;
    return JSON.stringify(allPageSEO, null, 2);
};
// Wrapper for the page's <head> element.
export const Head: React.FC<Props> = ({
    title = "Easy Agile",
    image = null,
    description = "Agile made easy for everyone on your team",
    lang = "en",
    type = "website",
    canonicalUrl = null,
    preventIndex = false,
    contentGroup = "default",
    onCustomizeStructuralJSON = null,
    allowCookieBanner = true,
}) => {
    const brandedTitle = `${title} | Easy Agile`;

    const data = useStaticQuery(graphql`
        query {
            ogImg: file(name: { eq: "ogImg" }) {
                publicURL
            }
        }
    `);

    const ogImg = image || data?.ogImg?.publicURL;
    const imageUrl = ogImg?.includes("http")
        ? ogImg
        : `${EA_WWW_WEBSITE_URL}${ogImg}`;

    let parsedCanonicalUrl;

    try {
        parsedCanonicalUrl = new URL(canonicalUrl);
        if (parsedCanonicalUrl.hostname === EA_WEBSITE_URL) {
            parsedCanonicalUrl.hostname = EA_WWW_WEBSITE_URL;
        }
    } catch {
        parsedCanonicalUrl = canonicalUrl;
    }

    return (
        <Location>
            {({ location }) => {
                const currentUrl = `${EA_WEBSITE_URL}${location.pathname}`;

                const metaInfo: MetaTag[] = getMetaTags(
                    currentUrl,
                    description,
                    imageUrl,
                    type,
                    (title = brandedTitle),
                    preventIndex
                );

                return (
                    <>
                        <Helmet
                            htmlAttributes={{ lang }}
                            title={brandedTitle}
                            meta={metaInfo}
                        >
                            {/* prefetch tag manager on pages showing the banner */}
                            {allowCookieBanner && (
                                <link
                                    rel="dns-prefetch"
                                    href="https://www.googletagmanager.com/"
                                />
                            )}
                            {!allowCookieBanner && (
                                <style>
                                    {`
                                        #hs-banner-parent {
                                            display: none;
                                        }
                                    `}
                                </style>
                            )}

                            <script type="application/ld+json">
                                {getLdJSON({
                                    url: currentUrl,
                                    title: brandedTitle,
                                    metaInfo,
                                    description,
                                    onCustomizeStructuralJSON,
                                })}
                            </script>
                            {canonicalUrl && (
                                <link
                                    rel="canonical"
                                    href={
                                        parsedCanonicalUrl?.href || canonicalUrl
                                    }
                                />
                            )}
                        </Helmet>
                        <ContentGrouping
                            key={location.href}
                            contentGroup={contentGroup}
                        />
                    </>
                );
            }}
        </Location>
    );
};

/**
 * The content grouping is derived from the funnelStage in Landing Pages and Blogs
 * and allows the marketing team to segment various page views by funnel stage in the buyer journey
 */
const ContentGrouping = ({ contentGroup }) => {
    const contentGroupName = contentGroup || ("default" as string);
    useEffect(() => {
        if (typeof window !== undefined && window.dataLayer) {
            // @ts-expect-error
            window.dataLayer.push({ content_group: contentGroupName });
            window.dataLayer.push({
                event: "content-group-loaded",
            });
        }
    }, []);
    return (
        <Helmet>
            <meta property="x:ea-content-group" value={contentGroupName}></meta>
        </Helmet>
    );
};
