import styled, { css } from "styled-components";
import { theme } from "../../styles/theme";
import { gridSquares } from "../../styles/grid";
import { internalInlineLinkStyle } from "../Link";

export const listStyle = (p) => css`
    width: 100%;
    margin: 1.5em 0;

    ${p.isBullets &&
    css`
        list-style: disc;
        padding-left: ${gridSquares(1)};
    `}

    ${p.isDashes &&
    css`
        > li:before {
            content: "-";
            text-indent: -0.5em;
            padding-right: 0.5em;
            margin-left: -1em;
        }
        list-style: none;
        padding-left: ${gridSquares(1)};
    `}

    li {
        width: 100%;
        margin: 0.3em 0;

        strong {
            font-weight: ${p.theme.fontWeight.heavy};
        }

        a {
            ${internalInlineLinkStyle}
        }

        ${p.color &&
        css`
            color: ${p.color};
        `}
    }
`;

interface PropsType {
    color?: string;
    isBullets?: boolean;
    isDashes?: boolean;
}

export const List = styled.ul<PropsType>`
    ${listStyle}
`;
